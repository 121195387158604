import React from 'react'
import { HashRouter } from 'react-router-dom'
import Authorized from './Authorized'

const Router = () => {
  return (
    <HashRouter basename='/'>
      <Authorized />
    </HashRouter>
  )
}

export default Router
