import React from 'react'
import * as Icons from './assets'

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const Icon = ({ name, size, ...props }) => {
  // @ts-ignore
  const Icon = Icons?.[capitalizeFirstLetter?.(name)] ?? <></>

  return (
    <img
      src={Icon}
      style={{ width: '100%', height: '100%', margin: 0, padding: 0 }}
    />
  )
}
export default Icon
