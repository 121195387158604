import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isValidPhoneNumber } from 'react-phone-number-input'
import Media from 'react-media'
import Logo from '../../assets/images/logo.svg'
import Profile from '../../assets/images/profile.svg'
import Profile1 from '../../assets/images/profile1.svg'
import Profile2 from '../../assets/images/profile2.svg'
import Location from '../../assets/images/location.svg'
import Duration from '../../assets/images/waitingBlack.svg'

import Icon from '../../components/Icon/Icon.js'
import Clear from '../../assets/images/clear.svg'
import Apple from '../../assets/images/apple.svg'
import Google from '../../assets/images/google.svg'
import Route from '../../assets/images/route.svg'
import pattern from '../../assets/images/pattern.png'
import people from '../../assets/images/people.png'

import { Spinner } from 'react-activity'
import 'react-activity/dist/library.css'
import Ok from '../../assets/images/ok.svg'
import 'add-to-calendar-button'
import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import axios from 'axios'
import moment from 'moment'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { atcb_action } from 'add-to-calendar-button'
import { formatMinutesToHoursAndMinutes } from '../../utils/formats'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const HomePage = () => {
  const history = useHistory()
  const url = process.env.REACT_APP_API_BASE_URL

  const { planzId, participantId, itinId } = useParams()

  const bottomRef = useRef(null)

  const [date, setDate] = useState(null)
  const [showDecline, setShowDecline] = useState(false)
  const [showAccept, setShowAccept] = useState(false)
  const [showWill, setShowWill] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [loading, setLoading] = useState(true)
  const [activeTimeSlots, setActiveTimeSlots] = useState([])
  const [phoneNumber, setPhoneNumber] = useState('')
  const [name, setName] = useState('')
  const [participant, setParticipant] = useState(null)
  const [showError, setShowError] = useState(false)
  const [sended, setSended] = useState(false)
  const [more, setMore] = useState(false)

  const getDate = async () => {
    try {
      const response = await axios.get(`${url}/meetings/get_planz/${planzId}`)
      setDate(response.data)
      setLoading(false)
    } catch (error) {}
  }

  useEffect(() => {
    getDate()
  }, [])

  useEffect(() => {
    if (date) {
      const archivedSlots = JSON.parse(localStorage.getItem('timeslots'))

      if (
        archivedSlots &&
        !activeTimeSlots.length &&
        archivedSlots[0]?.date_id == date?.id
      ) {
        setActiveTimeSlots(archivedSlots)
      } else {
      }
    }
  }, [date])

  const generateName = (type = '') => {
    const parts = type.split('-')
    return parts.join('')
  }

  const acceptSlots = async (will_bring_person, onSuccess, id) => {
    if (activeTimeSlots.length) {
      const acceptances = activeTimeSlots.map((el) => ({
        participant: id ?? participantId ?? participant,
        will_bring_person,
        timeslot: el?.id,
      }))

      try {
        await axios
          .post(`${url}/meetings/acceptances/`, { acceptances })
          .then((res) => {
            setSended(true)
            onSuccess()
          })
      } catch (error) {}
    }
  }

  const declineSlots = async (onSuccess) => {
    try {
      await axios.post(
        `${url}/meetings/reject/${participantId ?? participant}/`
      )
      onSuccess()
    } catch (error) {}
  }

  const zoom = 12

  const onPressMap = () => {
    const { latitude, longitude, location_description } = date?.location || {}

    const mapsUrl = `https://maps.google.com/?q=${latitude},${longitude}`
    window.open(encodeURI(mapsUrl), '_blank', 'noreferrer')
  }

  const accept = (acc, id) => {
    localStorage.setItem(
      'timeslots',
      JSON.stringify(
        activeTimeSlots.map((el) => ({ ...el, date_id: date?.id }))
      )
    )

    acceptSlots(acc, () => {}, id)
    setShowCalendar(true)
  }

  useEffect(() => {
    date?.timeslots?.forEach((el) => {
      el?.acceptances?.forEach((item) => {
        if (item?.participant_id === date?.participant_id) {
          setActiveTimeSlots((prev) => [...prev, el])
        }
      })
    })
  }, [date])
  useEffect(() => {
    if (date?.timeslots.length === 1 && !activeTimeSlots.length) {
      setActiveTimeSlots((prev) => [...prev, date?.timeslots[0]])
    }
  }, [date])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user?.name) {
      setPhoneNumber(user?.phone)
      setName(user?.name)
    }
  }, [])

  const handleNext = (phone = '') => {
    if (name.length) {
      if (isValidPhoneNumber(phoneNumber)) {
        localStorage.setItem(
          'user',
          JSON.stringify({ phone: phoneNumber, name })
        )
        createParticipant()
        setShowError(false)
      } else {
        setShowError(true)
      }
    } else {
      alert('Name is too short')
    }
  }

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event)
    showError && setShowError(false)
  }

  const handleNameChange = (event) => {
    if (
      /^[A-Za-z\.`’]{0,12}$/g.test(event.target.value) &&
      !event.target.value.includes('.')
    )
      setName(event.target.value)
  }

  const createParticipant = async () => {
    const user = JSON.parse(localStorage.getItem('user'))

    try {
      const response = await axios.post(
        `${url}/meetings/planz/participant_by_phone/`,
        {
          planz: planzId,
          phone: user?.phone,
          name: user?.name,
        }
      )

      setParticipant(response.data.id)
      setShowInput(false)
      setTimeout(() => {
        if (date?.participant_can_bring_person) {
          setShowWill(true)
        } else {
          accept(false, response.data.id)
        }
      }, 500)
    } catch (error) {}
  }
  // console.log(date)
  const [disable, setDisable] = useState(false)
  return (
    <>
      <div>
        {/* <Media
          queries={{
            small: '(max-width: 550px)',
            medium: '(min-width: 551px) and (max-width: 1199px)',
            large: '(min-width: 1200px)',
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.small && ( */}
        <div style={{ padding: 10 }}>
          <>
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'white',
                zIndex: 20,
                display: loading ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img
                style={{ width: 50, height: 50, marginBottom: 10 }}
                src={Logo}
              />
              <Spinner />
              <div
                style={{
                  marginTop: 10,
                  fontFamily: 'DM Sans',
                  fontWeight: '700',
                  color: 'black',
                  fontSize: 18,
                  lineHeight: 1.5,
                  textAlign: 'center',
                }}
              >
                Loading invitation
              </div>
            </div>
          </>

          <div style={styles.firstWrapper}>
            <div style={{ height: 20, width: 100 }}></div>

            <div style={styles.logoWrapper}>
              <img style={styles.logo} src={Logo} />
            </div>
            <div style={styles.planzText}>{'planz'}</div>

            <div style={styles.datePhotoWrapper}>
              <div
                style={{
                  width: '100%',
                  // marginTop: date?.photo ? 180 : 255,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 10,
                }}
              >
                <div style={styles.userPhotoWrapper}>
                  <img
                    style={styles.userPhoto}
                    src={
                      date?.owner?.photos?.find((el) => el.type === 'PRIMARY')
                        ?.file
                    }
                  />
                </div>

                <div style={styles.inviteText}>
                  {`${date?.owner?.name} has invited you`}
                </div>
                {!!date?.url && (
                  <div
                    style={styles.articleText}
                    onClick={() => {
                      window.open(
                        encodeURI(
                          date?.url.includes('https') ||
                            date?.url.includes('http')
                            ? date?.url
                            : 'https://' + date?.url
                        ),
                        '_blank',
                        'noreferrer'
                      )
                    }}
                    // href={'https://youtube.com'}
                    // target='_blank'
                  >
                    Open article
                  </div>
                )}
              </div>

              {date?.description && (
                <div style={styles.dateDesc}>{date?.description}</div>
              )}

              {/* {date?.photo ? (
                <>
                  <img style={styles.datePhoto} src={date?.photo} />
                </>
              ) : (
                <div style={styles.mapWrapper}>
                  <div
                    style={styles.openMap}
                    onClick={() => {
                      onPressMap()
                    }}
                  >
                    Open map app
                  </div>
                  <LoadScript
                    googleMapsApiKey={'AIzaSyAg3Iwq1GIoHi8YJj4zBknrBuBvFpfEHDs'}
                  >
                    <GoogleMap
                      mapContainerStyle={{
                        width: '100%',
                        height: '100%',
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                      }}
                      center={{
                        lat: date?.location?.latitude,
                        lng: date?.location?.longitude,
                      }}
                      zoom={zoom}
                      options={{
                        fullscreenControl: false,
                        disableDefaultUI: true,
                        gestureHandling: false,
                        panControl: false,
                        scaleControl: false,
                      }}
                    >
                      <Marker
                        position={{
                          lat: date?.location?.latitude,
                          lng: date?.location?.longitude,
                        }}
                      />
                    </GoogleMap>
                  </LoadScript>
                </div>
              )} */}
              <div>
                {/* <img style={styles.datePhoto} src={date?.photo} /> */}
                {date?.photo ? (
                  <>
                    <img style={styles.datePhoto} src={date?.photo} />
                  </>
                ) : (
                  <>
                    <></>
                    {date?.location?.latitude && (
                      <div style={styles.mapWrapper}>
                        <div
                          style={styles.openMap}
                          onClick={() => {
                            onPressMap()
                          }}
                        >
                          Open map app
                        </div>
                        <LoadScript
                          googleMapsApiKey={
                            'AIzaSyAg3Iwq1GIoHi8YJj4zBknrBuBvFpfEHDs'
                          }
                        >
                          <GoogleMap
                            mapContainerStyle={{
                              width: '100%',
                              height: '100%',
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderBottomRightRadius: 16,
                              border: 0,
                            }}
                            center={{
                              lat: date?.location?.latitude,
                              lng: date?.location?.longitude,
                            }}
                            zoom={zoom}
                            options={{
                              fullscreenControl: false,
                              disableDefaultUI: true,
                              gestureHandling: false,
                              panControl: false,
                              scaleControl: false,
                              gestureHandling: 'none',
                              clickableIcons: false,
                            }}
                          >
                            <Marker
                              position={{
                                lat: date?.location?.latitude,
                                lng: date?.location?.longitude,
                              }}
                            />
                          </GoogleMap>
                        </LoadScript>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div style={styles.userName}>{date?.name}</div>
              {date?.google_description && (
                <>
                  <div style={styles.dateDesc}>
                    {`${
                      more
                        ? date?.google_description
                        : date?.google_description?.slice(0, 100)
                    }${
                      date?.google_description?.length > 100 && !more
                        ? '...'
                        : ''
                    } `}
                  </div>
                  {date?.google_description?.length > 100 && (
                    <div
                      onClick={() => {
                        setMore((prev) => !prev)
                      }}
                    >
                      <div style={styles.dateDesc}>{`Show ${
                        !more ? 'more' : 'less'
                      }`}</div>
                    </div>
                  )}
                </>
              )}

              <div style={styles.line1}></div>
              <div style={styles.textAva}>
                {`Here is ${date?.owner?.name}' availability:`}
              </div>

              <div style={styles.timeSlotWrapper}>
                {date?.timeslots.map((el, index) => {
                  const isActive = activeTimeSlots?.find(
                    (slot) => slot?.id === el?.id
                  )
                  const slotBackgroundColor = isActive ? '#2AAD7A' : 'white'
                  const textColor = isActive ? 'white' : 'black'
                  const profileImage = isActive ? Profile1 : Profile2
                  const peopleCount =
                    el.acceptances.length === 0
                      ? '1 person'
                      : `${
                          el.acceptances.length +
                          1 +
                          el.acceptances.filter((e) => e.will_bring_person)
                            .length
                        } people`

                  return (
                    <div
                      key={index}
                      style={{
                        width: '31%',
                        height: 90,
                        cursor: 'pointer',

                        position: 'relative',
                        marginTop: 10,
                        backgroundColor: slotBackgroundColor,
                        marginRight: 5,
                        borderRadius: 8,
                        boxShadow: '0px 16px 40px rgba(210, 217, 222, 0.4)',
                      }}
                      onClick={() => {
                        if (isActive) {
                          setActiveTimeSlots((prev) => [
                            ...prev.filter((value) => value?.id !== el?.id),
                          ])
                        } else {
                          setActiveTimeSlots((prev) => [...prev, el])
                        }
                      }}
                    >
                      {date?.timeslots.length !== 1 && (
                        <div style={styles.optionWrapper}>{`Option ${
                          index + 1
                        }`}</div>
                      )}
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ marginLeft: 5 }}>
                          <div
                            style={{
                              fontFamily: 'DM Sans',
                              fontWeight: '700',
                              color: textColor,
                              fontSize: 12,
                              textAlign: 'center',
                            }}
                          >
                            {moment(el.datetime_start).format('ddd, MMM D')}
                          </div>
                          <div
                            style={{
                              fontFamily: 'DM Sans',
                              fontWeight: '400',
                              color: textColor === 'white' ? 'white' : 'black',
                              fontSize: 13,
                              textAlign: 'center',
                              marginTop: 5,
                            }}
                          >
                            {moment(el.datetime_start).format('h:mm a')}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              marginTop: 5,
                            }}
                          >
                            <div
                              style={{
                                width: 12,
                                height: 12,
                                marginRight: 3,
                              }}
                            >
                              <img
                                src={profileImage}
                                style={{ width: 12, height: 12 }}
                                alt='Profile'
                              />
                            </div>
                            <div
                              style={{
                                fontFamily: 'DM Sans',
                                fontWeight: '500',
                                color:
                                  textColor === 'white' ? 'white' : '#2AAD7A',
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 1,
                              }}
                            >
                              {peopleCount}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div
                style={{
                  fontFamily: 'DM Sans',
                  fontWeight: '400',
                  fontSize: 14,
                  textAlign: 'left',
                  lineHeight: 1.5,
                  width: '100%',
                  marginTop: 10,
                }}
              >
                {`Please select ALL the dates that work for you`}
              </div>
              <div
                style={{
                  width: '100%',
                  height: 1,
                  backgroundColor: '#DEE5E9',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></div>
              <div style={{ position: 'relative' }}>
                {date?.category?.type && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      height: 30,
                    }}
                  >
                    <div style={{ width: 20, height: 20, marginRight: 5 }}>
                      <Icon name={generateName(date?.category?.type)} />
                    </div>
                    <div
                      style={{
                        fontFamily: 'DM Sans',
                        fontWeight: '400',

                        fontSize: 14,
                      }}
                    >
                      {date?.category?.name}
                    </div>
                  </div>
                )}
                {date?.duration && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      height: 30,
                    }}
                  >
                    <div style={{ width: 20, height: 20, marginRight: 5 }}>
                      <img src={Duration} style={{}} />
                    </div>
                    <div
                      style={{
                        fontFamily: 'DM Sans',
                        fontWeight: '400',

                        fontSize: 14,
                      }}
                    >
                      {formatMinutesToHoursAndMinutes(date?.duration)}
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    height: 30,
                  }}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 5,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img src={Location} style={{}} />
                  </div>
                  <div
                    style={{
                      fontFamily: 'DM Sans',
                      fontWeight: '400',

                      fontSize: 14,
                      textOverflow: 'elipsis',
                      overflow: 'hidden',
                      maxHeight: 15,
                      width: '80%',
                    }}
                  >
                    {date?.location_description}
                  </div>
                </div>
                {date?.photo && (
                  <div
                    style={{
                      width: 18,
                      height: 18,
                      marginRight: 5,
                      cursor: 'pointer',
                      position: 'absolute',
                      right: 5,
                      top: 8,
                    }}
                    onClick={() => {
                      onPressMap()
                    }}
                  >
                    <img
                      src={Route}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                )}
              </div>

              <div
                style={{
                  width: '100%',
                  height: 1,
                  backgroundColor: '#DEE5E9',
                  marginTop: 10,
                }}
              ></div>
              <div style={styles.participantsContainer}>
                <div style={{ marginTop: 5 }}>
                  <img
                    src={
                      date?.owner?.photos?.find((el) => el.type === 'PRIMARY')
                        ?.file
                    }
                    style={styles.profileImage2}
                  />
                </div>

                {date?.participants.map((participant) => {
                  const willBringPerson = date.timeslots.some((timeslot) =>
                    timeslot.acceptances.some(
                      (acceptance) =>
                        acceptance.participant_id === participant?.id &&
                        acceptance.will_bring_person
                    )
                  )
                  const hasPrimaryPhoto = participant?.user?.photos?.some(
                    (photo) => photo.type === 'PRIMARY'
                  )

                  return (
                    <div
                      key={participant?.id}
                      style={styles.participantContainer}
                    >
                      {hasPrimaryPhoto ? (
                        <img
                          src={
                            participant?.user?.photos?.find(
                              (photo) => photo.type === 'PRIMARY'
                            )?.file
                          }
                          style={styles.profileImage}
                        />
                      ) : (
                        <div style={styles.defaultProfileImage}>
                          <img
                            src={Profile}
                            style={styles.defaultProfileIcon}
                          />
                        </div>
                      )}
                      {willBringPerson && (
                        <div style={styles.extraPersonBadge}>+1</div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
            <div style={{ width: 20, height: 20 }}></div>
            <div
              style={{
                width: '95%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: participantId ? 'space-between' : 'center',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              {participantId && (
                <div
                  style={{
                    width: '45%',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    borderRadius: 48,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 5,
                    height: 40,
                    cursor: 'pointer',
                    color: 'white',
                    fontFamily: 'DM Sans',
                    fontSize: 16,
                    fontWeight: '500',
                  }}
                  onClick={() => {
                    declineSlots(() => {
                      setShowDecline(true)
                    })
                  }}
                >
                  Decline
                </div>
              )}

              <div
                style={{
                  width: '45%',
                  backgroundColor: 'white',
                  borderRadius: 48,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 5,
                  height: 40,
                  cursor: 'pointer',
                  color: '#2AAD7A',
                  fontFamily: 'DM Sans',
                  fontSize: 16,
                  fontWeight: '500',
                }}
                onClick={() => {
                  if (!disable) {
                    setDisable(true)
                    // setTimeout(() => {
                    //   setDisable(false)
                    // }, 1500)
                    const user = JSON.parse(localStorage.getItem('user'))

                    if (participantId) {
                      activeTimeSlots.length > 0
                        ? date?.participant_can_bring_person
                          ? setShowWill(true)
                          : accept(false)
                        : setShowAccept(true)
                    } else {
                      activeTimeSlots.length > 0
                        ? user?.name
                          ? handleNext()
                          : setShowInput(true)
                        : setShowAccept(true)
                    }
                  }
                }}
              >
                Accept invite
              </div>
            </div>

            <div
              style={{
                position: 'absolute',
                display:
                  showDecline ||
                  showAccept ||
                  showWill ||
                  showCalendar ||
                  showInput
                    ? 'flex'
                    : 'none',
                top: 0,
                left: 0,
                right: 0,
                bottom: -1182,
                backgroundColor: 'rgba(10, 33, 43, 0.35)',
                zIndex: 10,
              }}
            ></div>
            <div ref={bottomRef}></div>
            <div
              style={{
                position: 'fixed',
                display: showInput ? 'flex' : 'none',
                top: 200,
                botton: 100,
                zIndex: 20,
                height: window.innerHeight * 0.5,
                width: window.innerWidth * 0.3,
                maxWidth: 500,
                minWidth: 300,
                backgroundColor: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 20,
                padding: 20,
                flexDirection: 'column',
              }}
            >
              <div
                style={{ position: 'absolute', top: 20, right: 20 }}
                onClick={() => {
                  setShowInput(false)
                }}
              >
                <img src={Clear} />
              </div>

              <div
                style={{
                  fontFamily: 'DM Sans',
                  fontWeight: '500',
                  fontSize: 16,
                  marginBottom: 5,
                  width: '90%',
                  marginTop: 40,
                }}
              >
                Enter your name
              </div>
              <input
                value={name}
                onChange={handleNameChange}
                style={{
                  width: '90%',
                  fontFamily: 'DM Sans',
                  marginBottom: 10,
                  background: '#FFFFFF',

                  border: '1px solid #DEE5E9',
                  borderRadius: 48,
                  height: 40,
                  paddingLeft: 15,
                }}
                placeholder='Name'
              />
              <div
                style={{
                  fontFamily: 'DM Sans',
                  fontWeight: '500',
                  fontSize: 16,
                  marginBottom: 5,
                  width: '90%',
                }}
              >
                Enter your phone
              </div>
              <PhoneInput
                placeholder='Enter phone number'
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                smartCaret={false}
                numberInputProps={{
                  style: {
                    border: 0,
                    boxShadow: 'none',
                  },
                }}
                countrySelectProps={{}}
                defaultCountry='US'
                style={{
                  width: '85%',
                  fontFamily: 'DM Sans',
                  marginBottom: 10,
                  background: '#FFFFFF',

                  border: '1px solid #DEE5E9',
                  borderRadius: 48,
                  height: 40,
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              />

              {showError && (
                <div
                  style={{
                    fontFamily: 'DM Sans',
                    fontWeight: '500',
                    fontSize: 14,
                    marginBottom: 10,
                    color: 'red',
                    width: '90%',
                  }}
                >
                  Invalid phone
                </div>
              )}

              <div
                style={{
                  height: 50,
                  borderRadius: 48,
                  border: '1px solid #DEE5E9',
                  width: '95%',
                  backgroundColor: '#2AAD7A',
                  textAlign: 'center',
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontFamily: 'DM Sans',
                  fontSize: 16,
                  fontWeight: '500',
                  marginTop: 10,
                }}
                onClick={() => {
                  handleNext()
                }}
              >
                Next
              </div>
            </div>
            <div
              style={{
                position: 'fixed',
                display: showDecline ? 'flex' : 'none',
                top: 200,
                botton: 100,
                zIndex: 20,
                height: window.innerHeight * 0.5,
                width: window.innerWidth * 0.3,
                maxWidth: 500,
                minWidth: 300,
                backgroundColor: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 20,
                padding: 20,
                flexDirection: 'column',
              }}
            >
              <div
                style={{ position: 'absolute', top: 20, right: 20 }}
                onClick={() => {
                  setShowDecline(false)
                }}
              >
                <img src={Clear} />
              </div>
              <div
                style={{
                  width: 86,
                  height: 86,
                  borderRadius: 80,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0px 8px 24px rgba(210, 218, 222, 0.38)',
                  marginBottom: 10,
                }}
              >
                <img src={Ok} />
              </div>
              <div
                style={{
                  fontFamily: 'DM Sans',
                  fontWeight: '700',
                  fontSize: 30,
                  marginBottom: 10,
                }}
              >
                Okay
              </div>
              <div
                style={{
                  fontFamily: 'DM Sans',
                  fontWeight: '400',
                  fontSize: 16,
                  color: '#0A212B',
                  opacity: 0.8,
                  width: '70%',
                  textAlign: 'center',
                }}
              >
                {`We'll notify ${date?.owner?.name} about it, no worries`}
              </div>

              {/* <div>I am a modal</div> */}
            </div>
            <div
              style={{
                position: 'fixed',
                display: showAccept ? 'flex' : 'none',
                top: 200,
                botton: 100,
                zIndex: 20,
                height: window.innerHeight * 0.5,
                width: window.innerWidth * 0.3,
                maxWidth: 500,
                minWidth: 300,
                backgroundColor: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 20,
                padding: 20,
                flexDirection: 'column',
              }}
            >
              <div
                style={{ position: 'absolute', top: 20, right: 20 }}
                onClick={() => {
                  setShowAccept(false)
                }}
              >
                <img src={Clear} />
              </div>
              {/* <div
                        style={{
                          width: 86,
                          height: 86,
                          borderRadius: 80,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxShadow: '0px 8px 24px rgba(210, 218, 222, 0.38)',
                          marginBottom: 10,
                        }}
                      >
                        <img src={Ok} />
                      </div> */}
              <div
                style={{
                  fontFamily: 'DM Sans',
                  fontWeight: '700',
                  fontSize: 30,
                  marginBottom: 10,
                  width: '80%',
                  textAlign: 'center',
                }}
              >
                {`Please, select a date option above to accept the invitation`}
              </div>
              {/* <div
                        style={{
                          fontFamily: 'DM Sans',
                          fontWeight: '400',
                          fontSize: 16,
                          color: '#0A212B',
                          opacity: 0.8,
                          width: '70%',
                          textAlign: 'center',
                        }}
                      >
                        They will be notified about it
                      </div> */}

              {/* <div>I am a modal</div> */}
            </div>
            <div
              style={{
                position: 'fixed',
                display: showWill ? 'flex' : 'none',
                top: 200,
                botton: 100,
                zIndex: 20,
                height: window.innerHeight * 0.5,
                width: window.innerWidth * 0.3,
                maxWidth: 500,
                minWidth: 300,
                backgroundColor: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 20,
                padding: 20,
                flexDirection: 'column',
              }}
            >
              <div
                style={{ position: 'absolute', top: 20, right: 20 }}
                onClick={() => {
                  setShowWill(false)
                }}
              >
                <img src={Clear} />
              </div>

              <div
                style={{
                  fontFamily: 'DM Sans',
                  fontWeight: '700',
                  fontSize: 30,
                  marginBottom: 10,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {`You will come alone or with someone?`}
              </div>
              <div
                style={{
                  height: 50,
                  borderRadius: 48,
                  border: '1px solid #DEE5E9',
                  width: '90%',
                  textAlign: 'center',
                  color: '#2AAD7A',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontFamily: 'DM Sans',
                  fontSize: 16,
                  fontWeight: '500',
                }}
                onClick={() => {
                  setShowWill(false)
                  accept(true)
                }}
              >
                I’ll grab +1 person
              </div>
              <div
                style={{
                  marginTop: 8,
                  height: 50,
                  borderRadius: 48,
                  border: '1px solid #DEE5E9',
                  width: '90%',
                  backgroundColor: '#2AAD7A',
                  textAlign: 'center',
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontFamily: 'DM Sans',
                  fontSize: 16,
                  fontWeight: '500',
                }}
                onClick={() => {
                  setShowWill(false)

                  accept(false)
                }}
              >
                I'll come alone
              </div>
            </div>
            <div
              style={{
                position: 'fixed',
                display: showCalendar ? 'flex' : 'none',
                top: 200,
                botton: 100,
                zIndex: 20,
                height: window.innerHeight * 0.5,
                width: window.innerWidth * 0.3,

                maxWidth: 500,
                minWidth: 300,
                backgroundColor: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 20,
                padding: 20,
                flexDirection: 'column',
              }}
            >
              <div
                style={styles.cancelModal}
                onClick={() => {
                  setShowCalendar(false)
                }}
              >
                <img src={Clear} />
              </div>

              <div style={styles.addToCalendarText}>
                {`Do you want to add a planz to your calendar?`}
              </div>
              <div
                style={styles.notAddToCalendarButton}
                onClick={() => {
                  setShowCalendar(false)
                  bottomRef.current?.scrollIntoView({
                    behavior: 'smooth',
                  })
                }}
              >
                No, thanks
              </div>
              <div
                style={styles.addToCalendarButton}
                onClick={() => {
                  setShowCalendar(false)

                  const timeSlotDates = activeTimeSlots.map((el, index) => {
                    const date2 = moment(el?.datetime_start)

                    const startDate = date2.format('YYYY-MM-DD')
                    const endDate = moment(
                      +date2 +
                        3600000 * ((date?.duration ? date?.duration : 120) / 60)
                    ).format('YYYY-MM-DD')
                    const startTime = date2.format('HH:mm')
                    const endTime = moment(
                      +date2 +
                        3600000 * ((date?.duration ? date?.duration : 120) / 60)
                    ).format('HH:mm')

                    return {
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                      name: `${date?.name} Option ${index + 1}`,
                      location: date?.location_description,
                      description: date?.description,
                      startDate,
                      endDate,
                      startTime,
                      endTime,
                    }
                  })
                  atcb_action({
                    dates: timeSlotDates,
                    options: ['Google'],
                    label: 'Yes, sure',
                    name: 'Planz',
                  })

                  bottomRef.current?.scrollIntoView({
                    behavior: 'smooth',
                  })
                }}
              >
                Yes, sure!
              </div>
              {!!activeTimeSlots?.length && (
                <AddToCalendarButton
                  options={['Google']}
                  label='Yes, sure'
                  name='Title'
                ></AddToCalendarButton>
              )}
            </div>
          </div>
          {sended && (
            <div style={styles.secondScreenWrapper}>
              <div style={styles.responseLogo}>
                <img src={Logo} alt='React Logo' />
              </div>
              <div style={styles.responseText}>
                {`Your response has been sent to ${date?.owner?.name}`}
              </div>

              <div style={styles.getAppText}>
                Get the app to change your RSVP, receive updates, and join the
                conversation about these planz
              </div>
              <div
                style={styles.downloadButton}
                onClick={() => {
                  window.location.href =
                    'https://apps.apple.com/ua/app/planz/id1619780476'
                }}
              >
                <div style={styles.iconWrapper}>
                  <img src={Apple} alt='React Logo' />
                </div>
                <div style={styles.downloadTextWrapper}>
                  <div style={styles.smallDownloadText}>Download on the</div>
                  <div style={styles.downloadText}>App Store</div>
                </div>
              </div>

              <div
                style={styles.downloadButton}
                onClick={() => {
                  window.location.href =
                    'https://play.google.com/store/apps/details?id=com.planz'
                }}
              >
                <div style={styles.iconWrapper}>
                  <img src={Google} alt='React Logo' />
                </div>
                <div style={styles.downloadTextWrapper}>
                  <div style={styles.smallDownloadText}>Get it on</div>
                  <div style={styles.downloadText}>Google Play</div>
                </div>
              </div>

              <img
                src={require('../../assets/images/people.png')}
                alt='React Logo'
                style={styles.bottomLogo}
              />
            </div>
          )}
        </div>
        {/* )}
              {matches.medium && (
                <div style={styles.emptyWrapper}>
                  <Spinner />
                </div>
              )}
              {matches.large && (
                <div style={styles.emptyWrapper}>
                  <Spinner />
                </div>
              )}
            </Fragment>
          )}
        </Media> */}
      </div>
    </>
  )
}

export default HomePage

const styles = {
  participantsContainer: {
    marginTop: 0,
    height: 70,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  participantContainer: {
    position: 'relative',
  },
  profileImage: {
    width: 44,
    height: 44,
    border: '1px white solid',
    borderRadius: 50,
    marginLeft: -5,
    zIndex: 4,
    objectFit: 'cover',
    marginTop: 5,
  },
  profileImage2: {
    width: 44,
    height: 44,
    border: '1px white solid',
    borderRadius: 50,
    marginLeft: 0,
    zIndex: 4,
    objectFit: 'cover',
  },
  defaultProfileImage: {
    width: 44,
    height: 44,
    border: '1px white solid',
    borderRadius: 50,
    backgroundColor: '#69787E',
    marginLeft: -5,
    zIndex: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultProfileIcon: {
    width: 30,
    height: 30,
    zIndex: 0,
  },
  extraPersonBadge: {
    position: 'absolute',
    width: 25,
    height: 25,
    backgroundColor: '#2AAD7A',
    borderRadius: 15,
    zIndex: 20,
    bottom: -2,
    right: -2,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'white',
    fontFamily: 'DM Sans',
    color: 'white',
    fontSize: 9,

    lineHeight: 2.7,

    textAlign: 'center',
  },
  optionWrapper: {
    fontFamily: 'DM Sans',
    fontWeight: '400',
    color: '#69787E',
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: 8,
    position: 'absolute',
    top: -20,
    left: 0,
    right: 0,
  },
  timeSlotWrapper: {
    height: 100,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    flexDirection: 'row',
    marginTop: 10,
    flexWrap: 'wrap',
  },
  textAva: {
    fontFamily: 'DM Sans',
    fontWeight: '400',
    fontSize: 14,
    textAlign: 'left',
    lineHeight: 1.5,
    width: '100%',
  },
  line1: {
    width: '100%',
    height: 1,
    backgroundColor: '#DEE5E9',
    marginTop: 10,
    marginBottom: 10,
  },
  dateDesc: {
    fontFamily: 'DM Sans',
    fontWeight: '400',
    fontSize: 14,
    textAlign: 'left',
    lineHeight: 1.5,
    width: '100%',
    color: '#69787E',
    marginBottom: 10,
  },
  userName: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    fontSize: 20,
    textAlign: 'left',
    lineHeight: 1.5,
    width: '100%',
    color: '#0A212B',
    marginTop: 0,
  },
  inviteText: {
    fontFamily: 'DM Sans',
    fontWeight: '500',
    fontSize: 14,
    textAlign: 'left',
    lineHeight: 1.5,
    width: '100%',
    color: '#0A212B',
  },
  articleText: {
    fontFamily: 'DM Sans',
    fontWeight: '500',
    fontSize: 14,
    textAlign: 'left',
    lineHeight: 1.5,
    width: '35%',
    color: '#69787E',
    borderRadius: 20,
    backgroundColor: '#F7F9FA',
    textAlign: 'center',
    padding: 7,
    cursor: 'pointer',
  },
  userPhoto: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    width: 25,
    height: 25,
    borderRadius: 50,
  },
  userPhotoWrapper: {
    width: 25,
    height: 25,
    borderRadius: 50,
    marginRight: 10,
  },
  openMap: {
    position: 'absolute',
    height: 34,
    backgroundColor: 'white',
    right: 10,
    bottom: 10,
    zIndex: 2,
    borderRadius: 48,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'DM Sans',
    fontWeight: '500',
    fontSize: 14,
    color: '#2AAD7A',
    cursor: 'pointer',
  },
  mapWrapper: {
    position: 'relative',
    width: '100%',
    height: window.innerHeight * 0.3,

    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    marginBottom: 10,
  },
  datePhoto: {
    width: '100%',
    height: window.innerHeight * 0.3,

    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    marginBottom: 10,
    objectFit: 'cover',
  },
  datePhotoWrapper: {
    backgroundColor: 'white',
    borderRadius: 16,
    width: '85%',
    marginTop: 20,
    padding: 15,
    position: 'relative',
    overflow: 'hidden',
    maxWidth: 700,
  },
  planzText: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    color: 'white',
    fontSize: 30,
    lineHeight: 1.5,
    textAlign: 'center',
  },
  logo: {
    width: 20,
  },
  logoWrapper: {
    width: 40,
    height: 40,
    backgroundColor: 'white',
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  firstWrapper: {
    width: '100wv',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: `url(${pattern})`,
    flexDirection: 'column',
    backgroundColor: '#2AAD7A',
    backgroundRepeat: 'repeat-x',
  },
  cancelModal: { position: 'absolute', top: 20, right: 20 },
  addToCalendarText: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    fontSize: 30,
    marginBottom: 10,
    width: '100%',
    textAlign: 'center',
  },
  notAddToCalendarButton: {
    height: 50,
    borderRadius: 48,
    border: '1px solid #DEE5E9',
    width: '90%',
    textAlign: 'center',
    color: '#2AAD7A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'DM Sans',
    fontSize: 16,
    fontWeight: '500',
  },
  addToCalendarButton: {
    marginTop: 8,
    height: 50,
    borderRadius: 48,
    border: '1px solid #DEE5E9',
    width: '90%',
    backgroundColor: '#2AAD7A',
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'DM Sans',
    fontSize: 16,
    fontWeight: '500',
  },
  secondScreenWrapper: {
    marginTop: 20,

    width: '100wv',
    height: window.innerHeight * 0.8,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: `url(${pattern})`,
    flexDirection: 'column',
    backgroundColor: '#2AAD7A',
    backgroundRepeat: 'repeat-x',
    position: 'relative',
  },
  responseLogo: {
    marginTop: 30,

    width: 50,
    height: 50,
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  responseText: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    color: 'white',
    marginTop: 20,
    fontSize: 34,
    lineHeight: 1.5,
    textAlign: 'center',
    width: '90%',
  },
  getAppText: {
    fontFamily: 'DM Sans',
    fontWeight: '500',
    color: 'white',
    marginTop: 20,
    fontSize: 16,
    textAlign: 'center',
    width: '70%',
    lineHeight: 1.5,
    marginBottom: 10,
  },
  downloadButton: {
    marginTop: 10,
    // width: '70%',
    maxWidth: 500,
    minWidth: 300,

    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    cursor: 'pointer',
  },
  iconWrapper: {
    width: 50,
    height: 50,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  downloadTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    // minWidth: 200,
    // maxWidth: 500,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  smallDownloadText: {
    fontFamily: 'DM Sans',
    fontWeight: '500',
    color: 'white',
    fontSize: 12,
    textAlign: 'left',
  },
  downloadText: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    color: 'white',
    fontSize: 22,
    textAlign: 'left',
  },
  bottomLogo: {
    objectFit: 'cover',
    width: 400,
    maxWidth: 500,
    borderRadius: 20,
    position: 'absolute',
    bottom: 0,
    height: 150,
  },
  emptyWrapper: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
}
