import React, { Fragment } from 'react'
import Media from 'react-media'
import { useHistory } from 'react-router-dom'

import Logo from '../../assets/images/logo.svg'
import Apple from '../../assets/images/apple.svg'
import Google from '../../assets/images/google.svg'
import { Spinner } from 'react-activity'

const AppPage = () => {
  const history = useHistory()
  return (
    <>
      <div
        onClick={() => {
          // history.push('/home')
        }}
        // style={{ width: '300px', height: 300, backgroundColor: 'red' }}
      >
        <Media
          queries={{
            small: '(max-width: 550px)',
            medium: '(min-width: 551px) and (max-width: 1199px)',
            large: '(min-width: 1200px)',
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.small && (
                <div
                  style={{
                    // height: '100vh',
                    width: '100wv',
                    background:
                      'linear-gradient(167.96deg, #3FC595 0%, #238472 100%)',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                ></div>
              )}
              {matches.medium && (
                <div
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Spinner />
                </div>
              )}
              {matches.large && (
                <div
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Spinner />
                </div>
              )}
            </Fragment>
          )}
        </Media>
      </div>
    </>
  )
}

export default AppPage
