import AcceptPage from '../pages/Accept/Accept'
import AppPage from '../pages/App/App'
import HomePage from '../pages/Home/Home'
import ItinPage from '../pages/Itin/Itin'

export const RoutesTree = {
  home: {
    name: 'home',
    path: '/home/:planzId',
    path2: '/home/:planzId/:participantId',
    path3: '/itinerary/:itinId',
    component: HomePage,
  },
  itin: {
    path: '/itinerary/:itinId',
    path2: '/itinerary/:itinId/:participantId',

    component: ItinPage,
  },
  app: {
    name: 'app',
    path: '/app',
    component: AppPage,
  },
  accept: {
    name: 'accept',
    path: '/accept',
    component: AcceptPage,
  },
}
