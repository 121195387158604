import moment from 'moment'
import { convertFrom24To12Format } from './formats'

export const generateAvailableTime = (start, end) => {
  const today = new Date()
  const isToday = new Date(start).getDate() === today.getDate()
  const result = []
  const startHours = new Date(start).getHours()
  const startMinutes = new Date(start).getMinutes()
  const endHours = new Date(end).getHours()
  const endMinutes = new Date(end).getMinutes()

  for (
    let i = startHours;
    i < (endHours > startHours ? endHours : endHours + 24);
    i++
  ) {
    for (let j = 0; j < 4; j++) {
      const minutes = j === 0 ? `00` : 15 * j
      const res = `${i}:${minutes}`
      result.push({
        value: convertFrom24To12Format(res),
        date: res,
        hours: i,
        minutes: minutes,
      })
    }
    i + 1 === endHours &&
      result.push({
        value: convertFrom24To12Format(`${endHours}:${'00'}`),
        hours: endHours,
        minutes: 0,
        date: endHours,
      })
  }

  if (startMinutes !== 0) {
    result.splice(0, 2)
  }
  if (endMinutes !== 0) {
    result.push({
      value: convertFrom24To12Format(`${endHours}:${'15'}`),
      hours: endHours,
      minutes: 15,
      date: endHours,
    })
    result.push({
      value: convertFrom24To12Format(`${endHours}:${'30'}`),
      hours: endHours,
      minutes: 30,
      date: endHours,
    })
  }
  return isToday
    ? result.filter((el) => {
        const current = new Date()
        current.setHours(el.hours)
        current.setMinutes(el.minutes)
        return current > today
      })
    : result
}

export function generateDateRangeObjects(startDate, endDate) {
  const dateObjects = []
  let currentDate = moment(startDate)
  let id = 1

  while (currentDate.isSameOrBefore(endDate, 'day')) {
    dateObjects.push({
      id,
      date: currentDate.clone(),
    })
    id++

    currentDate.add(1, 'day')
  }

  return dateObjects
}
