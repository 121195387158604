import React, { useState } from 'react'

import Icon from '../../components/Icon/Icon'
import { formatMinutesToHoursAndMinutes } from '../../utils/formats'

const ItinCard = React.memo(({ item }) => {
  const [more, setMore] = useState(false)

  const generateName = (type = '') => {
    const parts = type.split('-')
    return parts.join('')
  }
  const renderContent = () => (
    <div
      style={{
        padding: 16,
      }}
    >
      {!!item?.name && <div style={styles.dateName}>{item?.name}</div>}
      {item?.description?.length && (
        <>
          <div style={styles.dateDescription}>
            {`${more ? item?.description : item?.description.slice(0, 100)}${
              item?.description.length > 100 && !more ? '...' : ''
            } `}
          </div>
          {item?.description.length > 100 && (
            <div
              onClick={() => {
                setMore((prev) => !prev)
              }}
            >
              <div style={styles.dateDescription}>{`Show ${
                !more ? 'more' : 'less'
              }`}</div>
            </div>
          )}
        </>
      )}

      {item?.location_description && (
        <>
          <div style={styles.topLine} />
          <div
            style={{
              height: 27,
              ...styles.categoryContainer,
              // backgroundColor: 'red',
            }}
          >
            {!!item?.category?.id && (
              <div style={{ display: 'flex', marginTop: 5 }}>
                <div style={styles.iconWrapper}>
                  <Icon name={generateName(item?.category?.type)} />
                </div>
                <div style={{ marginRight: 30, ...styles.categoryText }}>
                  {item?.category?.name ?? 'category'}
                </div>
              </div>
            )}
            {!!item?.duration && (
              <div style={{ display: 'flex', marginTop: 5 }}>
                <div style={styles.iconWrapper}>
                  <Icon name={'waiting'} />
                </div>
                <div style={styles.categoryText}>
                  {formatMinutesToHoursAndMinutes(item?.duration)}
                </div>
              </div>
            )}
          </div>
          <div style={{ display: 'flex', marginTop: 5 }}>
            <div style={styles.iconWrapper}>
              <Icon name='locationGrey' />
            </div>
            <div style={styles.locationText}>
              {item?.location_description ?? 'Unknown location'}
            </div>
          </div>
        </>
      )}
    </div>
  )

  return (
    <div
      style={{
        // marginRight: item?.attraction_thumbnail ? 5 : 0,
        alignSelf: 'center',
        ...styles.container,
        boxShadow: '0px 2px 60px 0px #D2DADE80',
      }}
    >
      {item?.photo || item?.event_thumbnail || item?.attraction_thumbnail ? (
        <>
          <img
            style={styles.dateImage}
            src={
              item?.photo
                ? `https://ik.imagekit.io/appvales/media/${
                    (item?.photo || '').split('media/')[1]
                  }`
                : item?.event_thumbnail ?? item?.attraction_thumbnail
            }
          />
        </>
      ) : (
        <></>
      )}
      {renderContent()}
    </div>
  )
})

export default ItinCard
const styles = {
  editButton: {
    position: 'absolute',
    top: 15,
    right: 15,
    backgroundColor: '#FFFFFF3D',
    borderRadius: 20,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    zIndex: 20,
  },
  container2: {
    display: 'flex',

    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',

    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    width: 28,
    height: 28,
  },
  googleIcon: {
    padding: 3,
    backgroundColor: '#F7F9FA',
    borderRadius: 20,
    borderColor: '#EDF0F2',
    borderWidth: 1,
  },
  text: {
    marginLeft: 10,
    fontFamily: 'DM Sans',
    fontSize: 12,
    color: '#69787E',
  },
  buttonContainer2: {
    borderRadius: 48,
    // backgroundColor: '#F1F9FA',
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 15,
    borderWidth: 1,
    borderColor: '#EDF0F2',
  },
  buttonText2: {
    fontFamily: 'DM Sans',
    fontSize: 12,
    color: '#69787E',
    fontWeight: '500',
  },
  containerStyle: {
    height: 80,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxStyle: {
    width: '33%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: 'transparent',
  },
  dateTextStyle: {
    fontWeight: '700',
    fontFamily: 'DM Sans',
    fontSize: 12,
    color: 'black',
    marginBottom: 5,
  },
  timeTextStyle: {
    fontWeight: '400',
    fontFamily: 'DM Sans',
    fontSize: 14,
    color: '#69787E',
    marginBottom: 5,
  },
  mapIcon: {
    width: 18,
    height: 18,
    position: 'absolute',
    right: 5,
  },
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    width: window.innerWidth * 0.9,
    minWidth: 300,
    maxWidth: 400,

    // marginVertical: 4,

    marginBottom: 10,
  },
  dateImage: {
    height: 204,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,

    width: window.innerWidth * 0.9,
    maxWidth: 400,
  },
  mapWrapper: {
    // position: 'absolute',
    overflow: 'hidden',
    // top: 0,
    // left: 0,
    // right: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  openMap: {
    position: 'absolute',
    height: 34,
    backgroundColor: 'white',
    borderRadius: 48,
    zIndex: 100,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    top: 140,
    right: 10,
  },
  openText: {
    color: '#2AAD7A',
    fontFamily: 'DM Sans',
    fontSize: 14,
    fontWeight: '500',
  },
  mapStyle: {
    height: 180,
    zIndex: 10,
  },
  markerWrapper: {
    alignItems: 'center',
    width: 40,
    height: 40,
  },
  dateName: {
    color: '#0A212B',
    fontSize: 20,
    fontFamily: 'DM Sans',
    fontWeight: '500',
    marginBottom: 8,
  },
  dateDescription: {
    color: '#69787E',
    fontSize: 14,
    fontFamily: 'DM Sans',
    fontWeight: '400',
    marginBottom: 15,
  },
  topLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#DEE5E9',
    marginBottom: 15,
  },
  bottomLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#DEE5E9',
    marginTop: 5,
  },
  categoryContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  categoryWrapper: {
    width: '100%',
    height: 40,
    display: 'flex',

    flexDirection: 'row',
    alignItems: 'center',
  },
  locationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconWrapper: {
    width: 18,
    height: 18,
    marginRight: 5,
  },
  categoryText: {
    fontFamily: 'DM Sans',
    fontSize: 14,
    color: '#0A212B',
  },
  locationText: {
    fontFamily: 'DM Sans',
    fontSize: 14,
    color: '#0A212B',
    width: '85%',
  },
  buttonContainer: {
    display: 'flex',

    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  infoButton: {
    backgroundColor: 'white',
    marginLeft: 10,
  },
  buttonText: {
    fontFamily: 'DM Sans',
    fontSize: 16,
    fontWeight: '500',
    color: 'white',
  },
  infoButtonText: {
    color: '#2AAD7A',
  },
}
