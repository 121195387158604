import moment from 'moment'
import React, { memo, useEffect } from 'react'

const DateSelectInvite = ({ dates, onChange, active, setActiveDate }) => {
  useEffect(() => {
    // dates.length && onChange(new Date(dates[0].datetime_from))
  }, [dates])

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: 20,
      }}
    >
      {dates.map((el, i) => {
        const day = new Date()

        return (
          <>
            {true && (
              <div
                key={i}
                style={{
                  backgroundColor: active === i ? '#2AAD7A' : 'white',
                  margin: 4,
                  padding: 10,
                  width: 70,
                  height: 55,
                  borderRadius: 12,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0px 16px 40px rgba(210, 217, 222, 0.4)',
                }}
                onClick={() => {
                  onChange(day)
                  setActiveDate(i)
                }}
              >
                <div
                  // style={[
                  //   styles.text,
                  //   active == i ? styles.activeText : { fontWeight: 'bold' },
                  // ]}
                  style={{
                    textAlign: 'center',
                    fontSize: 14,
                    color: active === i ? 'white' : 'black',
                    fontFamily: 'DM Sans',
                    fontWeight: '500',
                  }}
                >
                  {moment(day).format('ddd')}
                </div>
                <div
                  // style={[styles.text, active == i ? styles.activeText : {}]}
                  style={{
                    textAlign: 'center',
                    fontSize: 14,
                    color: active === i ? 'white' : 'black',
                    fontFamily: 'DM Sans',
                  }}
                >
                  {moment(day).format('MMM D')}
                </div>
              </div>
            )}
          </>
        )
      })}
    </div>
  )
}

export default DateSelectInvite
