import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Media from 'react-media'
import Logo from '../../assets/images/logo.svg'
import Icon from '../../components/Icon/Icon.js'
import DateSelectInvite from '../../components/Select/DateSelectInvite'

import Calendar from '../../assets/images/calendar.svg'
import BlockTimeSelect from '../../components/Select/BlockTimeSelect'

const AcceptPage = () => {
  const history = useHistory()
  const [activeDate, setActiveDate] = useState(0)
  const [inviteDate, setInviteDate] = useState(new Date())
  const [inviteTimeSlots, setInviteTimeSlots] = useState([
    new Date(),
    new Date(),
    new Date(),
  ])

  return (
    <>
      <div
        onClick={() => {}}
        // style={{ width: '300px', height: 300, backgroundColor: 'blue' }}
      >
        <Media
          queries={{
            small: '(max-width: 400px)',
            medium: '(min-width: 401px) and (max-width: 1199px)',
            large: '(min-width: 1200px)',
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.small && (
                <div
                  style={{
                    // height: '100vh',
                    width: '100wv',
                    background:
                      'linear-gradient(167.96deg, #3FC595 0%, #238472 100%)',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ height: 120, width: 20 }}></div>
                  <img
                    src={Logo}
                    style={{
                      position: 'absolute',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      top: 70,
                      left: 0,
                      right: 0,
                      width: 123,
                      height: 123,
                      borderRadius: 100,
                      border: '2px white solid',
                      backgroundColor: 'white',
                    }}
                  />
                  <div
                    style={{
                      width: '100%',
                      height: 500,
                      borderTopRightRadius: 20,
                      borderTopLeftRadius: 20,
                      backgroundColor: 'white',
                      paddingTop: 80,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        fontFamily: 'DM Sans',
                        fontWeight: '700',
                        color: '#0A212B',
                        marginTop: 15,
                        fontSize: 30,
                        lineHeight: 1.5,
                        textAlign: 'center',
                      }}
                    >
                      Thomas
                    </div>
                    <div
                      style={{
                        fontFamily: 'DM Sans',
                        fontWeight: '500',
                        color: '#69787E',
                        marginTop: 5,
                        fontSize: 16,
                        textAlign: 'center',
                        width: '70%',
                        lineHeight: 1.5,
                      }}
                    >
                      Received schedule expires in 7 days
                    </div>
                    <DateSelectInvite
                      active={activeDate}
                      setActiveDate={setActiveDate}
                      dates={inviteTimeSlots ?? []}
                      date={inviteDate}
                      onChange={setInviteDate}
                    />
                    <div
                      style={{
                        fontFamily: 'DM Sans',
                        fontWeight: '500',
                        color: '#69787E',
                        marginTop: 10,
                        fontSize: 16,
                        textAlign: 'center',
                        width: '70%',
                        lineHeight: 1.5,
                      }}
                    >
                      Planz starts in
                    </div>
                    <BlockTimeSelect
                      active={activeDate}
                      setActiveDate={setActiveDate}
                      dates={inviteTimeSlots ?? []}
                      date={inviteDate}
                      onChange={setInviteDate}
                    />
                    <div
                      style={{
                        width: '90%',
                        height: 50,
                        backgroundColor: '#2AAD7A',
                        borderRadius: 48,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontFamily: 'DM Sans',
                        fontSize: 16,
                        fontWeight: '500',
                      }}
                    >
                      Confirm selected planz
                    </div>
                  </div>
                </div>
              )}
              {/* {matches.medium && <p>I am medium!</p>} */}
              {/* {matches.large && <p>I am large!</p>} */}
            </Fragment>
          )}
        </Media>
      </div>
    </>
  )
}

export default AcceptPage
