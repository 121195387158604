import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isValidPhoneNumber } from 'react-phone-number-input'
import Logo from '../../assets/images/logo.svg'
import Profile from '../../assets/images/profile.svg'
import CalendarGrey from '../../assets/images/calendarGrey.svg'
import Routing from '../../assets/images/routing.svg'

import Clear from '../../assets/images/clear.svg'
import Apple from '../../assets/images/apple.svg'
import Google from '../../assets/images/google.svg'
import pattern from '../../assets/images/pattern.png'

import { Spinner } from 'react-activity'
import 'react-activity/dist/library.css'
import Ok from '../../assets/images/ok.svg'
import 'add-to-calendar-button'
import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import axios from 'axios'
import moment from 'moment'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { generateDateRangeObjects } from '../../utils/time.js'
import ItinCard from './ItinCard.jsx'

const ItinPage = () => {
  const history = useHistory()
  const url = process.env.REACT_APP_API_BASE_URL
  //for test
  const { planzId, participantId, itinId } = useParams()
  const bottomRef = useRef(null)

  const [date, setDate] = useState(null)
  const [days, setDays] = useState([])

  const [showDecline, setShowDecline] = useState(false)
  const [showAccept, setShowAccept] = useState(false)
  const [showWill, setShowWill] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [loading, setLoading] = useState(true)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [name, setName] = useState('')
  const [participant, setParticipant] = useState(null)
  const [showError, setShowError] = useState(false)
  const [sended, setSended] = useState(false)
  const [activeDate, setActiveDate] = useState(null)

  const [more, setMore] = useState(false)

  const getDate = async () => {
    try {
      if (itinId) {
        const [itineraryResponse, planzResponse] = await axios.all([
          axios.get(`${url}/itineraries/get_itinerary/${itinId}/`),
          axios.get(`${url}/itineraries/itinerary/${itinId}/planzs/`),
        ])
        // const ii = axios.post(`${url}/itineraries/chatbot/promt/`, {
        //   start_date: '2024-01-25',
        //   promt: 'Give mse some interesting places in Jamaica',
        //   duration: 2,
        // })
        // ii.then((res) => {
        //   console.log('ii', res)
        // })
        setLoading(false)
        setDate(itineraryResponse.data)
        setDays(
          generateDateRangeObjects(
            new Date(
              new Date(itineraryResponse.data?.start_date).setDate(
                itineraryResponse.data?.start_date?.split('-').pop()
              )
            ),
            new Date(
              new Date(itineraryResponse.data?.end_date).setDate(
                itineraryResponse.data?.end_date?.split('-').pop()
              )
            )
          )
        )
        // console.log(itineraryResponse.data)

        const generatedDays = generateDateRangeObjects(
          new Date(
            new Date(itineraryResponse.data?.start_date).setDate(
              itineraryResponse.data?.start_date?.split('-').pop()
            )
          ),
          new Date(
            new Date(itineraryResponse.data?.end_date).setDate(
              itineraryResponse.data?.end_date?.split('-').pop()
            )
          )
        )
        // console.log(generatedDays, planzResponse?.data?.results[0])
        if (Object.entries(planzResponse?.data.results[0]).length) {
          for (const [key, value] of Object.entries(
            planzResponse?.data?.results[0]
          )) {
            const a = generatedDays.find(
              (dat) => dat.date.format('YYYY-MM-DD') === key
            )
            // console.log(key, value)
            setDays((prev) =>
              prev.map((item) =>
                item?.id === a?.id ? { ...item, planz: value } : item
              )
            )
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  // console.log(days, date?.start_date)
  useEffect(() => {
    if (itinId) {
      getDate()
    }
  }, [itinId])

  useEffect(() => {
    setActiveDate(days[0])
  }, [days])

  const accept = (acc, id) => {
    try {
      axios.patch(
        `${url}/itineraries/tripmate/${
          participantId ?? id ?? participant
        }/web/`,
        {
          status: 'ACTIVE',
        }
      )

      setSended(true)
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({
          behavior: 'smooth',
        })
      }, 0)
    } catch (error) {}
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user?.name) {
      setPhoneNumber(user?.phone)
      setName(user?.name)
    }
  }, [])

  const handleNext = (phone = '') => {
    if (name.length) {
      if (isValidPhoneNumber(phoneNumber)) {
        localStorage.setItem(
          'user',
          JSON.stringify({ phone: phoneNumber, name })
        )
        createParticipant()
        setShowError(false)
      } else {
        setShowError(true)
      }
    } else {
      console.error('Name is too short')
      // Use a more user-friendly notification method instead of alert
    }
  }

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event)
    showError && setShowError(false)
  }

  const handleNameChange = (event) => {
    const isValidName =
      /^[A-Za-z\.`’]{0,12}$/g.test(event.target.value) &&
      !event.target.value.includes('.')
    if (isValidName) {
      setName(event.target.value)
    }
  }

  const createParticipant = async () => {
    const user = JSON.parse(localStorage.getItem('user'))

    try {
      const response = await axios.post(
        `${url}/itineraries/itinerary/tripmate_by_phone/`,
        {
          itinerary: itinId,
          phone: user?.phone,
          name: user?.name,
        }
      )
      setParticipant(response.data.id)
      setShowInput(false)

      setTimeout(() => {
        if (date?.tripmate_can_bring_person) {
          setShowWill(true)
        } else {
          accept(false, response.data.id)
        }
      }, 500)
    } catch (error) {
      console.error('Error creating participant:', error)
    }
  }

  useEffect(() => {
    document.body.style.overflow = showCalendar ? 'hidden' : 'scroll'
  }, [showCalendar])

  const [disable, setDisable] = useState(false)

  return (
    <>
      <div>
        <div style={styles.divider1}>
          <>
            <div
              style={{
                ...styles.loadingContainer,
                display: loading ? 'flex' : 'none',
              }}
            >
              <img style={styles.imageLogo} src={Logo} />
              <Spinner />
              <div style={styles.loadingText}>Loading invitation</div>
            </div>
          </>

          <div style={styles.firstWrapper}>
            <div style={styles.divider2}></div>

            <div style={styles.logoWrapper}>
              <img style={styles.logo} src={Logo} />
            </div>
            <div style={styles.planzText}>{'planz'}</div>

            <div style={styles.datePhotoWrapper}>
              <div style={styles.ownerImage}>
                <div style={styles.userPhotoWrapper}>
                  <img
                    style={styles.userPhoto}
                    src={
                      date?.owner?.photos?.find((el) => el.type === 'PRIMARY')
                        ?.file
                    }
                  />
                </div>

                <div style={styles.inviteText}>
                  {`${date?.owner?.name} has invited you`}
                </div>
              </div>

              {date?.description && (
                <div style={styles.dateDesc}>{date?.description}</div>
              )}

              <div>
                {date?.cover_image ? (
                  <>
                    <img style={styles.datePhoto} src={date?.cover_image} />
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div style={styles.userName}>{date?.name}</div>
              {date?.google_description && (
                <>
                  <div style={styles.dateDesc}>
                    {`${
                      more
                        ? date?.google_description
                        : date?.google_description?.slice(0, 100)
                    }${
                      date?.google_description?.length > 100 && !more
                        ? '...'
                        : ''
                    } `}
                  </div>
                  {date?.google_description?.length > 100 && (
                    <div
                      onClick={() => {
                        setMore((prev) => !prev)
                      }}
                    >
                      <div style={styles.dateDesc}>{`Show ${
                        !more ? 'more' : 'less'
                      }`}</div>
                    </div>
                  )}
                </>
              )}
              {date?.url && (
                <div
                  style={{ ...styles.dateDesc, color: '#2AAD7A' }}
                  onClick={() => {
                    window.open(
                      encodeURI(
                        date?.url.includes('https') ||
                          date?.url.includes('http')
                          ? date?.url
                          : 'https://' + date?.url
                      ),
                      '_blank',
                      'noreferrer'
                    )
                  }}
                >
                  {date?.url}
                </div>
              )}

              <div style={styles.line1}></div>

              <div style={styles.timeSlotWrapper}>
                {date?.start_date && (
                  <div style={styles.dateContainer}>
                    <div style={styles.dateIcon}>
                      <img src={CalendarGrey} style={{}} />
                    </div>
                    <div style={styles.dateText}>
                      {`${moment(date?.start_date).format(
                        'ddd MMM DD, YYYY'
                      )} - ${moment(date?.end_date).format(
                        'ddd MMM DD, YYYY'
                      )}`}
                    </div>
                  </div>
                )}
                {/* {date?.start_date && (
                  <div style={styles.dateContainer}>
                    <div style={{ width: 20, height: 20, marginRight: 5 }}>
                      <img src={LocationGrey} style={{}} />
                    </div>
                    <div style={styles.dateText}>{'Hungary'}</div>
                  </div>
                )} */}
                {date?.start_date && (
                  <div style={styles.dateContainer}>
                    <div style={styles?.dateIcon}>
                      <img src={Routing} style={{}} />
                    </div>
                    <div style={styles.dateText}>
                      {`${date?.itinerary_planzs?.length} places to visit`}
                    </div>
                  </div>
                )}
                <div
                  style={styles.moreDetails}
                  onClick={() => {
                    setShowCalendar(true)
                  }}
                >
                  More details
                </div>
              </div>

              <div style={styles.lined}></div>

              <div style={styles.participantsContainer}>
                <div style={styles.invitedText}>{`Invited users (${
                  date?.tripmates?.filter((el) => el?.status === 'ACTIVE')
                    ?.length + 1
                })`}</div>
                <div style={styles.tripWrapper}>
                  <div style={styles.divider3}>
                    <img
                      src={
                        date?.owner?.photos?.find((el) => el.type === 'PRIMARY')
                          ?.file
                      }
                      style={styles.profileImage2}
                    />
                  </div>

                  {date?.tripmates
                    .filter((el) => el?.status === 'ACTIVE')
                    .map((participant) => {
                      const willBringPerson = date?.timeslots
                        ? date.timeslots.some((timeslot) =>
                            timeslot.acceptances.some(
                              (acceptance) =>
                                acceptance.participant_id === participant?.id &&
                                acceptance.will_bring_person
                            )
                          )
                        : false
                      const hasPrimaryPhoto = participant?.user?.photos?.some(
                        (photo) => photo.type === 'PRIMARY'
                      )

                      return (
                        <div
                          key={participant?.id}
                          style={styles.participantContainer}
                        >
                          {hasPrimaryPhoto ? (
                            <img
                              src={
                                participant?.user?.photos?.find(
                                  (photo) => photo.type === 'PRIMARY'
                                )?.file
                              }
                              style={styles.profileImage}
                            />
                          ) : (
                            <div style={styles.defaultProfileImage}>
                              <img
                                src={Profile}
                                style={styles.defaultProfileIcon}
                              />
                            </div>
                          )}
                          {willBringPerson && (
                            <div style={styles.extraPersonBadge}>+1</div>
                          )}
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
            <div style={styles.divider4}></div>
            <div
              style={{
                ...styles.buttonWrapper,
                justifyContent: participantId ? 'space-between' : 'center',
              }}
            >
              {participantId && (
                <div style={styles.declineButton} onClick={() => {}}>
                  Decline
                </div>
              )}

              <div
                style={styles.acceptButton}
                onClick={() => {
                  if (!disable) {
                    setDisable(true)
                    // setTimeout(() => {
                    //   setDisable(false)
                    // }, 1500)
                    const user = JSON.parse(localStorage.getItem('user'))
                    if (participantId) {
                      date?.tripmate_can_bring_person
                        ? setShowWill(true)
                        : accept()
                    } else {
                      user?.name ? handleNext() : setShowInput(true)
                    }
                  }
                }}
              >
                Accept invite
              </div>
            </div>

            <div
              style={{
                ...styles.backDrop,
                display:
                  showDecline ||
                  showAccept ||
                  showWill ||
                  showCalendar ||
                  showInput
                    ? 'flex'
                    : 'none',
              }}
            ></div>
            <div ref={bottomRef}></div>

            <div
              style={{
                ...styles.inputContainer,
                display: showInput ? 'flex' : 'none',
              }}
            >
              <div
                style={styles.inputClose}
                onClick={() => {
                  setShowInput(false)
                }}
              >
                <img src={Clear} />
              </div>

              <div style={styles.inputName}>Enter your name</div>
              <input
                value={name}
                onChange={handleNameChange}
                style={styles.inputNameEnter}
                placeholder='Name'
              />
              <div style={styles.inputPhone}>Enter your phone</div>
              <PhoneInput
                placeholder='Enter phone number'
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                smartCaret={false}
                numberInputProps={{
                  style: {
                    border: 0,
                    boxShadow: 'none',
                  },
                }}
                countrySelectProps={{}}
                defaultCountry='US'
                style={styles.inputPhoneEnter}
              />

              {showError && <div style={styles.showError}>Invalid phone</div>}

              <div
                style={styles.nextButton}
                onClick={() => {
                  handleNext()
                }}
              >
                Next
              </div>
            </div>
            <div
              style={{
                ...styles.declineContainer,
                display: showDecline ? 'flex' : 'none',
              }}
            >
              <div
                style={styles.inputClose}
                onClick={() => {
                  setShowDecline(false)
                }}
              >
                <img src={Clear} />
              </div>
              <div style={styles.okWrapper}>
                <img src={Ok} />
              </div>
              <div style={styles.okText}>Okay</div>
              <div style={styles.okDesc}>
                {`We'll notify ${date?.owner?.name} about it, no worries`}
              </div>
            </div>
            <div
              style={{
                ...styles.acceptContainer,
                display: showAccept ? 'flex' : 'none',
              }}
            >
              <div
                style={styles.inputClose}
                onClick={() => {
                  setShowAccept(false)
                }}
              >
                <img src={Clear} />
              </div>

              <div style={styles.acceptText}>
                {`Please, select a date option above to accept the invitation`}
              </div>
            </div>
            <div
              style={{
                ...styles.willContainer,
                display: showWill ? 'flex' : 'none',
              }}
            >
              <div
                style={styles.inputClose}
                onClick={() => {
                  setShowWill(false)
                }}
              >
                <img src={Clear} />
              </div>

              <div style={styles.willText}>
                {`You will come alone or with someone?`}
              </div>
              <div
                style={styles.willGrab}
                onClick={() => {
                  setShowWill(false)
                  accept(true)
                }}
              >
                I’ll grab +1 person
              </div>
              <div
                style={styles.willAlone}
                onClick={() => {
                  setShowWill(false)

                  accept(false)
                }}
              >
                I'll come alone
              </div>
            </div>
            <div
              onClick={() => {}}
              style={{
                ...styles.tripContainer,
                display: showCalendar ? 'flex' : 'none',
              }}
            >
              <div
                style={styles.cancelModal}
                onClick={() => {
                  setShowCalendar(false)
                }}
              >
                <img src={Clear} />
              </div>

              <div style={styles.tripTitle}>
                {`${date?.name ?? 'Itinerary'}`}
              </div>
              <div style={styles.tripWrappes}>
                <div style={styles.tripDays}>
                  {days?.map((el) => (
                    <div
                      onClick={() => {
                        setActiveDate(el)
                      }}
                      style={{
                        ...styles.tripDay,
                        backgroundColor:
                          activeDate?.id === el?.id ? '#2AAD7A' : 'white',
                      }}
                    >
                      <div
                        style={{
                          ...styles.tripDayText,
                          color: activeDate?.id === el?.id ? 'white' : 'black',
                        }}
                      >
                        {el?.date.format('ddd MMM DD')}
                      </div>
                    </div>
                  ))}
                </div>

                {days?.map((el) => (
                  <div
                    style={{
                      ...styles.dayCard,
                      display:
                        activeDate?.id === el?.id
                          ? 'flex'
                          : activeDate === null
                          ? 'flex'
                          : 'none',
                    }}
                  >
                    {el?.planz?.length ? (
                      el?.planz?.map((plan) => (
                        <div style={styles.planWrapper}>
                          {!!plan?.distance_metrics?.driving && (
                            <div style={styles.metricsText}>
                              <div style={styles.metricsLine}></div>
                              <div style={styles.metricsInfoWrapper}>
                                <div style={styles.metricsIcon}>
                                  {/* <Own name={'driving'} /> */}
                                </div>
                                <div style={styles.metricsDesc}>
                                  {`${plan.distance_metrics?.driving?.duration} • ${plan.distance_metrics?.driving.distance}`}
                                </div>
                                <div style={styles.metricsIcon2}>
                                  {/* <Own name={'dropDown'} /> */}
                                </div>
                              </div>

                              <div style={styles.metricsLine2}></div>
                            </div>
                          )}
                          <div style={styles.metricsStartText}>
                            {`Start time - ${moment(date?.start_date).format(
                              'hh:mm a'
                            )}`}
                          </div>
                          <ItinCard item={plan.planz} />
                        </div>
                      ))
                    ) : (
                      <>
                        <div style={styles.noPlan}>No plan items here yet</div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {sended && (
            <div style={styles.secondScreenWrapper}>
              <div style={styles.responseLogo}>
                <img src={Logo} alt='React Logo' />
              </div>
              <div style={styles.responseText}>
                {'Swipe with purpose make Planz®'}
              </div>

              <div style={styles.getAppText}>
                Preview the vibe with live video introductions. Enjoy a curated
                dating experience.
              </div>
              <div
                style={styles.downloadButton}
                onClick={() => {
                  window.location.href =
                    'https://apps.apple.com/ua/app/planz/id1619780476'
                }}
              >
                <div style={styles.iconWrapper}>
                  <img src={Apple} alt='React Logo' />
                </div>
                <div style={styles.downloadTextWrapper}>
                  <div style={styles.smallDownloadText}>Download on the</div>
                  <div style={styles.downloadText}>App Store</div>
                </div>
              </div>

              <div
                style={styles.downloadButton}
                onClick={() => {
                  window.location.href =
                    'https://play.google.com/store/apps/details?id=com.planz'
                }}
              >
                <div style={styles.iconWrapper}>
                  <img src={Google} alt='React Logo' />
                </div>
                <div style={styles.downloadTextWrapper}>
                  <div style={styles.smallDownloadText}>Get it on</div>
                  <div style={styles.downloadText}>Google Play</div>
                </div>
              </div>

              <img
                src={require('../../assets/images/people.png')}
                alt='React Logo'
                style={styles.bottomLogo}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ItinPage

const styles = {
  noPlan: {
    fontFamily: 'DM Sans',
    fontWeight: '400',
    fontSize: 20,
    marginBottom: 10,
    color: 'grey',
    width: '100%',
    textAlign: 'center',
  },
  willAlone: {
    marginTop: 8,
    height: 50,
    borderRadius: 48,
    border: '1px solid #DEE5E9',
    width: '90%',
    backgroundColor: '#2AAD7A',
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'DM Sans',
    fontSize: 16,
    fontWeight: '500',
  },
  willGrab: {
    height: 50,
    borderRadius: 48,
    border: '1px solid #DEE5E9',
    width: '90%',
    textAlign: 'center',
    color: '#2AAD7A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'DM Sans',
    fontSize: 16,
    fontWeight: '500',
  },
  willText: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    fontSize: 30,
    marginBottom: 10,
    width: '100%',
    textAlign: 'center',
  },
  willContainer: {
    position: 'fixed',
    top: 200,
    botton: 100,
    zIndex: 20,
    height: window.innerHeight * 0.5,
    width: window.innerWidth * 0.3,
    maxWidth: 500,
    minWidth: 300,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    padding: 20,
    flexDirection: 'column',
  },
  acceptText: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    fontSize: 30,
    marginBottom: 10,
    width: '80%',
    textAlign: 'center',
  },
  acceptContainer: {
    position: 'fixed',
    top: 200,
    botton: 100,
    zIndex: 20,
    height: window.innerHeight * 0.5,
    width: window.innerWidth * 0.3,
    maxWidth: 500,
    minWidth: 300,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    padding: 20,
    flexDirection: 'column',
  },
  okDesc: {
    fontFamily: 'DM Sans',
    fontWeight: '400',
    fontSize: 16,
    color: '#0A212B',
    opacity: 0.8,
    width: '70%',
    textAlign: 'center',
  },
  okText: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    fontSize: 30,
    marginBottom: 10,
  },
  okWrapper: {
    width: 86,
    height: 86,
    borderRadius: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 8px 24px rgba(210, 218, 222, 0.38)',
    marginBottom: 10,
  },
  declineContainer: {
    position: 'fixed',
    top: 200,
    botton: 100,
    zIndex: 20,
    height: window.innerHeight * 0.5,
    width: window.innerWidth * 0.3,
    maxWidth: 500,
    minWidth: 300,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    padding: 20,
    flexDirection: 'column',
  },
  nextButton: {
    height: 50,
    borderRadius: 48,
    border: '1px solid #DEE5E9',
    width: '95%',
    backgroundColor: '#2AAD7A',
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'DM Sans',
    fontSize: 16,
    fontWeight: '500',
    marginTop: 10,
  },
  showError: {
    fontFamily: 'DM Sans',
    fontWeight: '500',
    fontSize: 14,
    marginBottom: 10,
    color: 'red',
    width: '90%',
  },
  inputPhoneEnter: {
    width: '85%',
    fontFamily: 'DM Sans',
    marginBottom: 10,
    background: '#FFFFFF',

    border: '1px solid #DEE5E9',
    borderRadius: 48,
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
  },
  inputPhone: {
    fontFamily: 'DM Sans',
    fontWeight: '500',
    fontSize: 16,
    marginBottom: 5,
    width: '90%',
  },
  inputNameEnter: {
    width: '90%',
    fontFamily: 'DM Sans',
    marginBottom: 10,
    background: '#FFFFFF',

    border: '1px solid #DEE5E9',
    borderRadius: 48,
    height: 40,
    paddingLeft: 15,
  },
  inputName: {
    fontFamily: 'DM Sans',
    fontWeight: '500',
    fontSize: 16,
    marginBottom: 5,
    width: '90%',
    marginTop: 40,
  },
  inputClose: { position: 'absolute', top: 20, right: 20 },
  inputContainer: {
    position: 'fixed',
    top: 200,
    botton: 100,
    zIndex: 20,
    height: window.innerHeight * 0.5,
    width: window.innerWidth * 0.3,
    maxWidth: 500,
    minWidth: 300,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    padding: 20,
    flexDirection: 'column',
  },
  backDrop: {
    position: 'absolute',

    top: 0,
    left: 0,
    right: 0,
    bottom: -1182,
    backgroundColor: 'rgba(10, 33, 43, 0.35)',
    zIndex: 10,
  },
  buttonWrapper: {
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  divider4: { width: 20, height: 20 },
  divider3: { marginTop: 5 },
  divider2: { height: 20, width: 100 },
  divider1: { padding: 10 },
  loadingContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  metricsStartText: {
    color: '#0A212B',
    fontSize: 14,
    fontFamily: 'DM Sans',
    opacity: 0.5,
    marginTop: 5,
    marginBottom: 5,
  },
  metricsLine2: {
    width: '23%',
    backgroundColor: '#0A212B',
    height: 1,
    opacity: 0.5,
  },
  metricsIcon2: {
    width: 15,
    height: 15,
    marginLeft: 5,
    opacity: 0.5,
  },
  metricsDesc: {
    color: '#0A212B',
    fontSize: 12,
    fontFamily: 'DM Sans',
    opacity: 0.5,
  },
  metricsIcon: {
    width: 15,
    height: 15,
    marginRight: 5,
    opacity: 0.5,
  },
  metricsInfoWrapper: {
    flexDirection: 'row',
    display: 'flex',
  },
  metricsLine: {
    width: '23%',
    backgroundColor: '#0A212B',
    height: 1,
    opacity: 0.5,
  },
  metricsText: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    marginBottom: 5,
    opacity: 0.5,
  },
  planWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  dayCard: {
    minHeight: 146,
    backgroundColor: 'white',
    marginBottom: 80,
    padding: 20,
    flexDirection: 'column',
  },
  tripDayText: { fontFamily: 'DM Sans', fontSize: 16 },
  tripDay: {
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    height: 40,
    marginRight: 10,
    marginBottom: 10,
    boxShadow: '0px 2px 60px 0px #D2DADE80',
  },
  tripDays: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // width: window.innerWidth * 0.9,
    width: '100%',
    maxWidth: 500,
  },
  tripWrappes: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tripTitle: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    fontSize: 30,
    marginBottom: 10,
    width: '100%',
    textAlign: 'center',
  },
  tripContainer: {
    position: 'fixed',
    top: 135,
    botton: 0,
    left: 0,
    right: 0,
    zIndex: 20,
    height: window.innerHeight * 0.9,
    maxWidth: 700,

    backgroundColor: 'white',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 20,
    padding: 20,
    paddingTop: 40,
    overflowY: 'scroll',
    overflowX: 'hidden',
    margin: '0 auto',
    flexDirection: 'column',
  },
  acceptButton: {
    width: '45%',
    backgroundColor: 'white',
    borderRadius: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    height: 40,
    cursor: 'pointer',
    color: '#2AAD7A',
    fontFamily: 'DM Sans',
    fontSize: 16,
    fontWeight: '500',
  },
  declineButton: {
    width: '45%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    height: 40,
    cursor: 'pointer',
    color: 'white',
    fontFamily: 'DM Sans',
    fontSize: 16,
    fontWeight: '500',
  },
  tripWrapper: {
    display: 'flex',
    width: '80%',
    flexDirection: 'row-reverse',
  },
  invitedText: {
    width: 60,
    height: 35,
    color: 'black',
    fontSize: 14,
    fontFamily: 'DM Sans',
  },
  lined: {
    width: '100%',
    height: 1,
    backgroundColor: '#DEE5E9',
    marginTop: 10,
    marginBottom: 10,
  },
  moreDetails: {
    width: '97%',
    backgroundColor: 'white',
    borderRadius: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    height: 40,
    cursor: 'pointer',
    color: '#2AAD7A',
    fontFamily: 'DM Sans',
    fontSize: 14,
    fontWeight: '500',
    marginTop: 5,
    boxShadow: '0px 2px 60px 0px #D2DADE80',
  },
  dateIcon: { width: 20, height: 20, marginRight: 5 },
  dateText: {
    fontFamily: 'DM Sans',
    fontWeight: '400',

    fontSize: 14,
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 30,
  },
  ownerImage: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  loadingText: {
    marginTop: 10,
    fontFamily: 'DM Sans',
    fontWeight: '700',
    color: 'black',
    fontSize: 18,
    lineHeight: 1.5,
    textAlign: 'center',
  },
  imageLogo: { width: 50, height: 50, marginBottom: 10 },
  participantsContainer: {
    marginTop: 0,
    height: 70,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  participantContainer: {
    position: 'relative',
  },
  profileImage: {
    width: 44,
    height: 44,
    border: '1px white solid',
    borderRadius: 50,
    marginRight: -5,
    zIndex: 4,
    objectFit: 'cover',
    marginTop: 5,
  },
  profileImage2: {
    width: 44,
    height: 44,
    border: '1px white solid',
    borderRadius: 50,
    marginRight: 0,
    zIndex: 4,
    objectFit: 'cover',
  },
  defaultProfileImage: {
    width: 44,
    height: 44,
    border: '1px white solid',
    borderRadius: 50,
    backgroundColor: '#69787E',
    marginRight: -5,
    marginTop: 5,
    zIndex: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultProfileIcon: {
    width: 30,
    height: 30,
    zIndex: 0,
  },
  extraPersonBadge: {
    position: 'absolute',
    width: 25,
    height: 25,
    backgroundColor: '#2AAD7A',
    borderRadius: 15,
    zIndex: 20,
    bottom: -2,
    right: -2,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'white',
    fontFamily: 'DM Sans',
    color: 'white',
    fontSize: 9,

    lineHeight: 2.7,

    textAlign: 'center',
  },
  optionWrapper: {
    fontFamily: 'DM Sans',
    fontWeight: '400',
    color: '#69787E',
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: 8,
    position: 'absolute',
    top: -20,
    left: 0,
    right: 0,
  },
  timeSlotWrapper: {
    height: 160,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 10,
  },
  textAva: {
    fontFamily: 'DM Sans',
    fontWeight: '400',
    fontSize: 14,
    textAlign: 'left',
    lineHeight: 1.5,
    width: '100%',
  },
  line1: {
    width: '100%',
    height: 1,
    backgroundColor: '#DEE5E9',
    marginTop: 10,
    marginBottom: 10,
  },
  dateDesc: {
    fontFamily: 'DM Sans',
    fontWeight: '400',
    fontSize: 14,
    textAlign: 'left',
    lineHeight: 1.5,
    width: '100%',
    color: '#69787E',
    marginBottom: 10,
  },
  userName: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    fontSize: 20,
    textAlign: 'left',
    lineHeight: 1.5,
    width: '100%',
    color: '#0A212B',
    marginTop: 0,
  },
  inviteText: {
    fontFamily: 'DM Sans',
    fontWeight: '500',
    fontSize: 14,
    textAlign: 'left',
    lineHeight: 1.5,
    width: '100%',
    color: '#0A212B',
  },
  articleText: {
    fontFamily: 'DM Sans',
    fontWeight: '500',
    fontSize: 14,
    textAlign: 'left',
    lineHeight: 1.5,
    width: '35%',
    color: '#69787E',
    borderRadius: 20,
    backgroundColor: '#F7F9FA',
    textAlign: 'center',
    padding: 7,
    cursor: 'pointer',
  },
  userPhoto: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    width: 25,
    height: 25,
    borderRadius: 50,
  },
  userPhotoWrapper: {
    width: 25,
    height: 25,
    borderRadius: 50,
    marginRight: 10,
  },
  openMap: {
    position: 'absolute',
    height: 34,
    backgroundColor: 'white',
    right: 10,
    bottom: 10,
    zIndex: 2,
    borderRadius: 48,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'DM Sans',
    fontWeight: '500',
    fontSize: 14,
    color: '#2AAD7A',
    cursor: 'pointer',
  },
  mapWrapper: {
    position: 'relative',
    width: '100%',
    height: window.innerHeight * 0.3,

    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    marginBottom: 10,
  },
  datePhoto: {
    width: '100%',
    height: window.innerHeight * 0.3,

    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    marginBottom: 10,
    objectFit: 'cover',
  },
  datePhotoWrapper: {
    backgroundColor: 'white',
    borderRadius: 16,
    width: '85%',
    marginTop: 20,
    padding: 15,
    position: 'relative',
    overflow: 'hidden',
    maxWidth: 700,
  },
  planzText: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    color: 'white',
    fontSize: 30,
    lineHeight: 1.5,
    textAlign: 'center',
  },
  logo: {
    width: 20,
  },
  logoWrapper: {
    width: 40,
    height: 40,
    backgroundColor: 'white',
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  firstWrapper: {
    width: '100wv',
    miwWidth: 400,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: `url(${pattern})`,
    flexDirection: 'column',
    backgroundColor: '#2AAD7A',
    backgroundRepeat: 'repeat-x',
  },
  cancelModal: { position: 'absolute', top: 20, right: 20 },
  addToCalendarText: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    fontSize: 30,
    marginBottom: 10,
    width: '100%',
    textAlign: 'center',
  },
  notAddToCalendarButton: {
    height: 50,
    borderRadius: 48,
    border: '1px solid #DEE5E9',
    width: '90%',
    textAlign: 'center',
    color: '#2AAD7A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'DM Sans',
    fontSize: 16,
    fontWeight: '500',
  },
  addToCalendarButton: {
    marginTop: 8,
    height: 50,
    borderRadius: 48,
    border: '1px solid #DEE5E9',
    width: '90%',
    backgroundColor: '#2AAD7A',
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'DM Sans',
    fontSize: 16,
    fontWeight: '500',
  },
  secondScreenWrapper: {
    marginTop: 20,

    width: '100wv',
    height: window.innerHeight * 0.8,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: `url(${pattern})`,
    flexDirection: 'column',
    backgroundColor: '#2AAD7A',
    backgroundRepeat: 'repeat-x',
    position: 'relative',
  },
  responseLogo: {
    marginTop: 30,

    width: 50,
    height: 50,
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  responseText: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    color: 'white',
    marginTop: 20,
    fontSize: 34,
    lineHeight: 1.5,
    textAlign: 'center',
    width: '90%',
  },
  getAppText: {
    fontFamily: 'DM Sans',
    fontWeight: '500',
    color: 'white',
    marginTop: 20,
    fontSize: 16,
    textAlign: 'center',
    width: '70%',
    lineHeight: 1.5,
    marginBottom: 10,
  },
  downloadButton: {
    marginTop: 10,
    // width: '70%',
    maxWidth: 500,
    minWidth: 300,

    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    cursor: 'pointer',
  },
  iconWrapper: {
    width: 50,
    height: 50,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  downloadTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    // width: '50%',
    // minWidth: 200,
    // maxWidth: 500,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  smallDownloadText: {
    fontFamily: 'DM Sans',
    fontWeight: '500',
    color: 'white',
    fontSize: 12,
    textAlign: 'left',
  },
  downloadText: {
    fontFamily: 'DM Sans',
    fontWeight: '700',
    color: 'white',
    fontSize: 22,
    textAlign: 'left',
  },
  bottomLogo: {
    objectFit: 'cover',
    width: 400,
    maxWidth: 500,
    borderRadius: 20,
    position: 'absolute',
    bottom: 0,
    height: 150,
  },
  emptyWrapper: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
}
