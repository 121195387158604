import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RoutesTree } from './routes'

const Navigation = () => {
  return (
    <div style={{ minHeight: '100vh' }}>
      <Switch>
        <Route
          exact
          path={RoutesTree.home.path}
          component={RoutesTree.home.component}
        />
        <Route
          exact
          path={RoutesTree.home.path2}
          component={RoutesTree.home.component}
        />
        <Route
          exact
          path={RoutesTree.itin.path}
          component={RoutesTree.itin.component}
        />
        <Route
          exact
          path={RoutesTree.itin.path2}
          component={RoutesTree.itin.component}
        />

        <Route
          exact
          path={RoutesTree.app.path}
          component={RoutesTree.app.component}
        />
        <Route
          exact
          path={RoutesTree.accept.path}
          component={RoutesTree.accept.component}
        />

        <Redirect to={RoutesTree.app.path} />
      </Switch>
    </div>
  )
}

export default Navigation
