import React, { memo, useEffect } from 'react'
import { useState } from 'react'
import { generateAvailableTime } from '../../utils/time'

const BlockTimeSelect = ({ timeStart, timeEnd, setTime }) => {
  const [times, setTimes] = useState([])
  const [activeTime, setActiveTime] = useState('')

  useEffect(() => {
    times.length && setTime(times[0])
    times.length && setActiveTime(times[0])
  }, [times])

  useEffect(() => {
    timeStart && timeEnd && setTimes(generateAvailableTime(timeStart, timeEnd))
  }, [timeStart, timeEnd])

  useEffect(() => {
    activeTime && setTime(activeTime)
  }, [activeTime])

  const [pagination, setPagination] = useState(12)
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    times.length > pagination ? setShowMore(true) : setShowMore(false)
  }, [times, pagination])

  return (
    <>
      <div
        style={{
          width: '100%',
          height: 1,
          backgroundColor: '#DEE5E9',
        }}
      />
      <div
        style={{
          display: 'flex',
          marginLeft: 5,
          marginVertical: 10,
          width: '85%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {times?.map((el, index) => {
          const isActive = activeTime?.value === el?.value
          return (
            <>
              {index < pagination ? (
                <div
                  style={{
                    marginTop: 10,
                    marginRight: 5,
                    height: 30,
                    width: '23%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: isActive ? '#2AAD7A' : 'white',
                    borderRadius: 5,
                    paddingHorizontal: 7,
                    boxShadow: '0px 16px 40px rgba(210, 217, 222, 0.4)',
                  }}
                  onClick={() => {
                    setTime(el)
                    setActiveTime(el)
                  }}
                  activeOpacity={0}
                >
                  <div
                    style={{
                      fontSize: 12,
                      fontFamily: 'DM Sans',
                      color: isActive ? 'white' : 'black',
                    }}
                  >
                    {el.value}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )
        })}
      </div>
      {showMore && (
        <div
          onClick={() => {
            setPagination((prev) => prev + 12)
          }}
        >
          <div
            style={{
              fontSize: 16,
              fontFamily: 'DM Sans',
              marginBottom: 10,
              color: '#2AAD7A',
            }}
          >
            Show more
          </div>
        </div>
      )}

      <div
        style={{
          width: '100%',
          height: 1,
          backgroundColor: '#DEE5E9',
        }}
      />
    </>
  )
}

export default memo(BlockTimeSelect)
