export const convertFrom24To12Format = (time24) => {
  const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1)
  const period = +sHours < 12 || (+sHours > 23 && +sHours < 36) ? 'AM' : 'PM'
  const hours = +sHours % 12 || 12

  return `${hours}:${minutes} ${period}`
}

export function formatMinutesToHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return hours === 0
    ? `${minutes} minutes`
    : minutes === 0
    ? `${hours} hours`
    : `${hours} hours ${minutes} minutes`
}
